.verify-container
{
    margin: 15px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid white;
    padding: 10px 0px;
}

.id-card-wrapper {
    height: 50vh;
    display: flex;
  }
  .id-card {
    flex-basis: 100%;
    max-width:40em;
    border: 1px solid rgb(97, 245, 245);
    margin:  auto;
    color: #fff;
    padding: .5em;
    background-color: #17445E;
    box-shadow: 0px 0px 3px 1px #12a0a0, inset 0px 0px 3px 1px #12a0a0;
    border-radius: 10px;
  }
  
  .profile-row {
    display: flex;
  }
  .profile-row .dp {
    flex-basis: 33.3%;
    position: relative;
    margin: 24px;
    align-self: center;
  }
  .profile-row .desc {
    flex-basis: 66.6%;
  }
  
  .profile-row .dp img {
    max-width: 100%;
    border-radius: 50%;
    display: block;
    box-shadow: 0px 0px 4px 3px #12a0a0;
  }
  .profile-row .desc {
    padding: 1em;
  }
  
  .profile-row .dp .dp-arc-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid transparent;
    border-top-color: #0AE0DF;
    border-radius: 50%;
    top: -6px;
    left: -6px;
  
    animation-duration: 2s;
    animation-name: rotate-clock;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes rotate-clock {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .profile-row .dp .dp-arc-outer {
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 6px solid transparent;
    border-bottom-color: #0AE0DF;
    border-radius: 50%;
    top: -16px;
    left: -16px;
  
    animation-duration: 2s;
    animation-name: rotate-anticlock;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes rotate-anticlock {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  
  .profile-row .desc {
    font-family: 'Orbitron', sans-serif;
    color: #ecfcfb;
    letter-spacing: 1px;
  }
  .profile-row .desc h1 {
    margin: 0px;
  }

  .desc>p
  {
    color: #0AE0DF;
  }
    .getButton
    {
  
      padding: 20px;
      font-size: 20px;
      color: white;
      background-color: #216288;
      border-radius: 20px;
      cursor: pointer;
      border-color: white;
  
    }
    .getButton:hover{
        background-color: #17445E;
        text-transform: uppercase;
    }
  
    .idInput
    {
      padding: 20px;
      border-radius: 10px;
      border: chocolate;
    }
  
    .grid-container-element { 
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-gap: 20px; 
      width: 100%;
      margin: 20px auto; 
      
  } 
  .grid-child-element { 
      margin: 10px auto; 
      
      
  }
  .profile-header
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile-header>a{
    color: white;
    text-decoration: none;
    border: 1px solid #0AE0DF;
    padding: 5px 10px;
    border-radius: 0.2rem;
}
.profile-header>a:hover{
    color: #61dafb;
}