.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.statement
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
}
.statement>h1 {
  text-align: center;
  font-size: 1.5rem; 
  color: #006666;
  margin-bottom: 0;
}

.statement>p
{
  text-align: center;
  color: #e67e22;
  font-size: 20px;
  margin: 0;
}

.statement>a
{
    text-decoration: none;
    border: 1px solid #006666;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 0.2rem;
    color:#1687a0;
}

.lock {
  border-radius: 5px;
  width: 10vw; /* Scaled for responsive sizing */
  height: 9vw;
  max-width: 55px; /* Limit max size */
  max-height: 45px;
  background-color: #333;
  position: relative;
  animation: dip 1s ease-in-out;
}

.lock::before,
.lock::after {
  content: '';
  position: absolute;
  border-left: 0.3rem solid #333;
  height: 3vw;
  width: 3vw;
  left: calc(50% - 1.5vw);
}

.lock::before {
  top: -6vw;
  border: 0.3rem solid #333;
  border-bottom-color: transparent;
  border-radius: 1.5vw 1.5vw 0 0;
  height: 6vw;
  animation: lock 2s, spin 2s;
}

.lock::after {
  top: -2vw;
  border-right: 0.3rem solid transparent;
  animation: spin 2s;
}

#mailId
{
  color: #006666;
}

@keyframes lock {
  0% {
    top: -6vw;
  }
  65% {
    top: -6vw;
  }
  100% {
    top: -8vw;
  }
}

@keyframes spin {
  0% {
    transform: scaleX(-1);
    left: calc(50% - 4vw);
  }
  65% {
    transform: scaleX(1);
    left: calc(50% - 1.5vw);
  }
}

@keyframes dip {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Media query for small screens */
@media (max-width: 768px) {
  h1 {
    font-size: 1.2rem; /* Adjust text for smaller screens */
  }

  .lock {
    width: 15vw; /* Adjust size for smaller screens */
    height: 12vw;
  }

  .lock::before, .lock::after {
    height: 5vw;
    width: 4vw;
  }

  .lock::before {
    top: -10vw;
    height: 10vw;
  }

  .lock::after {
    top: -3vw;
  }
}
