/* /------------------Model------------------/ */
body.active-modal {
    overflow-y: hidden;
  }
  
  .btn-modal {
    padding: 10px 40px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
    z-index: 2;
  }
  
  .modal, .overlay {
    width: 100vw;
    height: 150vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  
  .overlay {
    background-color: rgba(52, 73, 94, 0.543);;
  }
  .modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f1f1f1;
    padding: 10px 28px;
    border-radius: 3px;
    
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 5px 7px;
  }
  
  .addBox
  {
    display: grid;
    margin: 40px 20px;
    gap: 10px;
    
  }
  
  .addBox input{
    margin-top: 10px;
    padding: 15px;
    width: 80%;
    border-radius: 10px;
    border: none;
  }

  .modal-content>button, .shareBtn{
    padding: 7px 12px;
    background-color: #1abc9c;
    color:white;
    border: none ;
    border-radius: 0.2rem;
    cursor: pointer;
  }

  .modal-content>span{
    font-size: medium;
    font-weight: bold;
    color: #006666;
  }
  .modal-content>button:hover, .shareBtn:hover{
    background-color: white;
    color:black;
    border: 1px solid #34495e;
  }


  /* Style for the select dropdown */
.dropdown {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

/* Style for each option (it won't affect checkbox or delete button) */
.shared-list
{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}



/* Checkbox styling inside select options */
.dropdown input[type="checkbox"] {
  cursor: pointer;
  margin: 0;
  width: 12px;
}


#remove-icon
{

  width: 25px;
  height: 25px;
  color:darkred ;
}
  