
.container
{

  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  margin: 20px;
  
}

.btn
{
  display: flex;
  gap: 20px;
}

.btn>button{
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 0.3rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    z-index: 2;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  form{
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
  }
form>h2{
  color:#006666;
}
 
.input-box
{
  position: relative;
}

.input-box>input
{
  outline: none;
  width: 500px;
  padding: 0px 7px;
  height: 40px;
  border-radius: 0.3rem;
  border: none;
  border: 1px solid #ccc;
  font-size: 16px;
  

}

.input-box>label
{
  position: absolute;
  top:10px;
  left: 7px;
  display: flex;
  align-items: center;
  color:#636c72;
  z-index: -1;
  transition: all 0.4s ease;
  cursor: text;
  
}

.input-box>input:focus+label,.input-box>input:valid+label{
  top: -12px;
  padding: 0;
  background-color: white;
  color: #216288;
  z-index: 1;
}

.input-box>input:focus, .input-box>input:valid{
  border: 1px solid #006666;
}

.input-box>input:focus::placeholder
{
  color: transparent;
}
  
  form>button
  {
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 0.3rem;
    outline: none;
    border: none;
    color: #FFF;
    background-color: #216288;
    cursor: pointer;
  }
  form>button:hover
  {
    background-color: #216288ad;
  }


  .page {
    width: 100%;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  /* When openPage is true (slide in from the right) */
  .slide-in-right {
    transform: translateX(100%);
    animation: slideFromRight 0.5s forwards;
  }

  #register-btn{
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 0.3rem;
    outline: none;
    border: none;
    cursor: pointer;
  }

  #solana-explorer{
    margin-left: 5px;
    padding: 8px 20px;
    font-size: 15px;
    border-radius: 0.3rem;
    border:1px  dashed;
    cursor: pointer;
    color: #006666;
    text-decoration: none;
  }
  
  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* When openPage is false (slide out to the right) */
  .slide-out-right {
    animation: slideToRight 0.5s forwards;
  }
  
  @keyframes slideToRight {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  /* When openPage is false (slide in from the left) */
  .slide-in-left {
    transform: translateX(-100%);
    animation: slideFromLeft 0.5s forwards;
  }
  
  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* When openPage is true (slide out to the left) */
  .slide-out-left {
    animation: slideToLeft 0.5s forwards;
  }
  
  @keyframes slideToLeft {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  @media screen and (max-width:634px) {

    .input-box>input
    {
      width: 350px;
      padding: 0px 10px;
      height: 50px;
      
    }
  }

  @media screen and (max-width:522px) {

    .form-container
    {
      width: 320px;
      padding: 10px 5px;
    }
    .input-box>input
    {
      width: 250px;
      padding: 0px 10px;
      height: 40px;
      
    }

  }