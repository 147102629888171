.finder-conatiner
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

form>h2{
    text-align: center;
}